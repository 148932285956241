var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4"},[_c('v-card-title',{staticClass:"clav-linear-background white--text"},[_vm._v(" "+_vm._s(_vm.p.objeto.acao)+" da Legislação "),_c('v-spacer'),(
        _vm.temPermissaoConsultarHistorico() &&
        !(
          _vm.p.objeto.acao === 'Criação' &&
          (_vm.p.estado === 'Submetido' || _vm.p.estado === 'Ressubmetido')
        )
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"white"},on:{"click":function($event){return _vm.verHistorico()}}},on),[_vm._v(" history ")])]}}],null,false,2323882188)},[_c('span',[_vm._v("Ver histórico de alterações...")])]):_vm._e()],1),_c('v-card-text',{staticClass:"mb-2 pt-2"},[(_vm.p.objeto.acao !== 'Criação')?_c('span',[_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"info","width":"90%","outlined":""}},[_c('span',[_c('b',[_vm._v(" "+_vm._s(_vm.p.objeto.tipo)+": ")]),_vm._v(" "+_vm._s(_vm.p.objeto.dadosOriginais.diplomaFonte)+" - "+_vm._s(_vm.p.objeto.dadosOriginais.numero)+" - "+_vm._s(_vm.p.objeto.dadosOriginais.sumario)+" ")])]),_c('v-divider',{staticClass:"m-auto mb-2"})],1):_vm._e(),_vm._l((_vm.dados),function(info,campo){return _c('Campo',{key:campo,attrs:{"nome":_vm.transformaKeys(campo),"color":"neutralpurple"},scopedSlots:_vm._u([{key:"conteudo",fn:function(){return [_c('div',[(!(info instanceof Array))?_c('div',[_vm._v(" "+_vm._s(info ? info : "O campo encontra-se vazio.")+" ")]):_c('div',[(campo == 'entidadesSel')?_c('v-data-table',{attrs:{"headers":_vm.entidadesHeaders,"items":info,"footer-props":_vm.footerPropsEntidades},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"error","width":"100%","outlined":""}},[_vm._v(" Nenhuma entidade selecionada... ")])]},proxy:true}],null,true)}):(campo == 'processosSel')?_c('v-data-table',{attrs:{"headers":_vm.processosHeaders,"items":info,"footer-props":_vm.footerPropsProcessos},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"error","width":"100%","outlined":""}},[_vm._v(" Nenhum processo selecionado... ")])]},proxy:true}],null,true)}):_vm._e()],1)])]},proxy:true}],null,true)})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }