var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-2"},[_c('v-card-title',{staticClass:"clav-linear-background white--text"},[_vm._v(" "+_vm._s(_vm.p.objeto.acao)+" da Classe "),_c('v-spacer'),(
        _vm.temPermissaoConsultarHistorico() &&
        !(
          _vm.p.objeto.acao === 'Criação' &&
          (_vm.p.estado === 'Submetido' || _vm.p.estado === 'Ressubmetido')
        )
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"white"},on:{"click":function($event){return _vm.verHistorico()}}},on),[_vm._v(" history ")])]}}],null,false,2323882188)},[_c('span',[_vm._v("Ver histórico de alterações...")])]):_vm._e()],1),_c('v-card-text',{staticClass:"pt-2"},_vm._l((_vm.classeInfo),function(c){return _c('Campo',{key:c.campo,attrs:{"nome":c.campo,"infoHeader":c.campo,"color":"neutralpurple"},scopedSlots:_vm._u([{key:"conteudo",fn:function(){return [_c('div',[(!(c.conteudo instanceof Array))?_c('span',[_vm._v(" "+_vm._s(c.conteudo)+" ")]):_c('div',[_c('v-data-table',{attrs:{"headers":_vm.notasAppHeader,"items":c.conteudo,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"error","width":"100%","outlined":""}},[_vm._v(" Nenhuma nota adicionada. ")])]},proxy:true},{key:"item.sigla",fn:function(ref){
      var item = ref.item;
return [(_vm.novoItemAdicionado(item, _vm.campo))?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(item.sigla))]):_c('span',[_vm._v(" "+_vm._s(item.sigla)+" ")])]}}],null,true)})],1)])]},proxy:true}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }