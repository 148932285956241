var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"indigo darken-4 title white--text",attrs:{"dark":""}},[_vm._v(" Histórico de alterações "),_c('v-spacer'),_c('v-chip',{attrs:{"color":"indigo accent-4","text-color":"white","label":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("label")]),_c('b',[_vm._v(_vm._s(_vm.etapaReferente))])],1),(_vm.dados.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-4",attrs:{"color":"white"},on:{"click":function($event){_vm.dialogVerHistoricoEmTabela = true}}},on),[_vm._v("table_chart")])]}}],null,false,3126535620)},[_c('span',[_vm._v("Comparar duas etapas do histórico em formato tabela...")])]):_vm._e()],1),_c('v-card-text',{staticClass:"mt-2"},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-window',{staticClass:"mt-2",model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.dados),function(h,i){return _c('v-window-item',{key:i},[_c('v-card',{staticClass:"rounded-card pa-4",attrs:{"shaped":"","color":_vm.corFundo[i]}},[(
                _vm.tipoPedido() &&
                _vm.pedido.objeto.tipo != 'Classe_N1' &&
                _vm.pedido.objeto.tipo != 'Classe_N2'
              )?_c('v-card-text',_vm._l((h),function(info,campo){return _c('div',{key:campo},[(
                    info.dados !== '' &&
                    info.dados !== null &&
                    campo !== 'estado' &&
                    campo !== 'codigo' &&
                    campo !== 'id'
                  )?_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"info-descricao",class:("info-descricao-" + (info.cor))},[_vm._v(" "+_vm._s(_vm.transformaKeys(campo))+" ")])]),_c('v-col',[(!(info.dados instanceof Array))?_c('div',{staticClass:"info-conteudo"},[_vm._v(" "+_vm._s(info.dados)+" ")]):_c('div',[(campo === 'entidadesSel')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.entidadesHeaders,"items":info.dados,"footer-props":_vm.footerPropsEntidades},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"error","width":"100%","outlined":""}},[_vm._v("Nenhuma entidade selecionada...")])]},proxy:true},{key:"item.sigla",fn:function(ref){
                  var item = ref.item;
return [(_vm.novoItemAdicionado(item, campo))?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(item.sigla))]):_c('span',[_vm._v(_vm._s(item.sigla))])]}}],null,true)}):(campo === 'tipologiasSel')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tipologiasHeaders,"items":info.dados,"footer-props":_vm.footerPropsTipologias},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"error","width":"100%","outlined":""}},[_vm._v("Nenhuma tipologia selecionada...")])]},proxy:true},{key:"item.sigla",fn:function(ref){
                  var item = ref.item;
return [(_vm.novoItemAdicionado(item, campo))?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(item.sigla))]):_c('span',[_vm._v(_vm._s(item.sigla))])]}}],null,true)}):(campo === 'processosSel')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.processosHeaders,"items":info.dados,"footer-props":_vm.footerPropsProcessos},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"error","width":"100%","outlined":""}},[_vm._v("Nenhum processo selecionado...")])]},proxy:true},{key:"item.codigo",fn:function(ref){
                  var item = ref.item;
return [(_vm.novoItemAdicionado(item, campo))?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(item.codigo))]):_c('span',[_vm._v(_vm._s(item.codigo))])]}}],null,true)}):(campo === 'zonaControlo')?_c('div',{staticClass:"info-conteudo"},[_c('ZonaControlo',{attrs:{"info":info}})],1):_c('div',{staticClass:"info-conteudo"},_vm._l((info.dados),function(i){return _c('span',{key:i},[_vm._v(_vm._s(i))])}),0)],1)]),_c('v-col',{attrs:{"cols":"1"}},[(info.nota)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.verNota(info.nota)}}},on),[_vm._v("message")])]}}],null,true)},[_c('span',[_vm._v("Ver nota relativa ao campo...")])]):_vm._e()],1)],1):_vm._e()],1)}),0):(
                _vm.pedido.objeto.tipo === 'Classe_N1' ||
                _vm.pedido.objeto.tipo === 'Classe_N2'
              )?_c('v-card-text',_vm._l((h),function(info,campo){return _c('div',{key:campo},[(_vm.classesAllowedInfo.includes(campo))?_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"info-descricao",class:("info-descricao-" + (info.cor))},[_vm._v(" "+_vm._s(_vm.transformaKeys(campo))+" ")])]),_c('v-col',[(!(info.dados instanceof Array))?_c('div',{staticClass:"info-conteudo"},[_vm._v(" "+_vm._s(info.dados)+" ")]):_c('div',[(campo === 'notasAp' || 'notasEx')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.notasHeaders,"items":info.dados},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"m-auto mb-2 mt-2",attrs:{"type":"error","width":"100%","outlined":""}},[_vm._v("Nenhuma nota adicionada.")])]},proxy:true},{key:"item.sigla",fn:function(ref){
              var item = ref.item;
return [(_vm.novoItemAdicionado(item, campo))?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(item.sigla))]):_c('span',[_vm._v(_vm._s(item.sigla))])]}}],null,true)}):(campo === 'zonaControlo')?_c('div',{staticClass:"info-conteudo"},[_c('ZonaControlo',{attrs:{"info":info}})],1):_c('div',{staticClass:"info-conteudo"},_vm._l((info),function(i){return _c('span',{key:i},[_vm._v(_vm._s(i))])}),0)],1)]),_c('v-col',{attrs:{"cols":"1"}},[(info.nota)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.verNota(info.nota)}}},on),[_vm._v("message")])]}}],null,true)},[_c('span',[_vm._v("Ver nota relativa ao campo...")])]):_vm._e()],1)],1):_vm._e()],1)}),0):(_vm.pedido.objeto.tipo === 'RADA')?_c('v-card-text',[_c('VerHistoricoRADA',{attrs:{"historico":h}})],1):(_vm.pedido.objeto.tipo.includes('TS '))?_c('v-card-text',[_c('VerHistoricoTS',{attrs:{"historico":h,"tipo":_vm.pedido.objeto.tipo}})],1):_vm._e()],1)],1)}),1),_c('v-card-actions',{staticClass:"justify-space-between"},[_c('v-btn',{on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("chevron_left")])],1),_c('v-item-group',{staticClass:"text-center",attrs:{"mandatory":""},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.dados),function(n,i){return _c('v-item',{key:("btn-" + i),scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
              var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"input-value":active,"icon":""},on:{"click":toggle}},[_c('b',[_vm._v(_vm._s(i + 1))])])]}}],null,true)})}),1),_c('v-btn',{on:{"click":_vm.next}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("chevron_right")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-4","text":"","rounded":"","dark":""},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Fechar")])],1),_c('v-dialog',{attrs:{"width":"90%","persistent":""},model:{value:(_vm.dialogVerHistoricoEmTabela),callback:function ($$v) {_vm.dialogVerHistoricoEmTabela=$$v},expression:"dialogVerHistoricoEmTabela"}},[(_vm.tipoPedido())?_c('VerHistoricoEmTabela',{attrs:{"historico":_vm.dados,"distribuicao":_vm.distribuicaoFormatada,"tipoPedido":_vm.pedido.objeto.tipo},on:{"fecharDialog":function($event){_vm.dialogVerHistoricoEmTabela = false}}}):(_vm.pedido.objeto.tipo === 'RADA')?_c('VerHistoricoRADATabela',{attrs:{"historico":_vm.dados,"distribuicao":_vm.distribuicaoFormatada},on:{"fecharDialog":function($event){_vm.dialogVerHistoricoEmTabela = false}}}):(
        _vm.pedido.objeto.tipo === 'TS Organizacional' || 'TS Pluriorganizacional'
      )?_c('VerHistoricoTSTabela',{attrs:{"historico":_vm.dados,"distribuicao":_vm.distribuicaoFormatada,"tipoPedido":_vm.pedido.objeto.tipo},on:{"fecharDialog":function($event){_vm.dialogVerHistoricoEmTabela = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.dialogVerNota.visivel),callback:function ($$v) {_vm.$set(_vm.dialogVerNota, "visivel", $$v)},expression:"dialogVerNota.visivel"}},[_c('v-card',[_c('v-card-title',{staticClass:"indigo darken-4 title white--text"},[_vm._v("Nota")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"value":_vm.dialogVerNota.nota,"auto-grow":"","outlined":"","readonly":"","color":"indigo"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"red darken-4",attrs:{"dark":""},on:{"click":function($event){return _vm.fecharDialogVerNota()}}},[_vm._v("Fechar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }