<template>
  <v-card class="mt-4">
    <v-card-title class="clav-linear-background white--text">
      {{ p.objeto.acao }} da Legislação
      <v-spacer />

      <v-tooltip
        v-if="
          temPermissaoConsultarHistorico() &&
          !(
            p.objeto.acao === 'Criação' &&
            (p.estado === 'Submetido' || p.estado === 'Ressubmetido')
          )
        "
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon @click="verHistorico()" color="white" v-on="on"> history </v-icon>
        </template>
        <span>Ver histórico de alterações...</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text class="mb-2 pt-2">
      <span v-if="p.objeto.acao !== 'Criação'">
        <v-alert type="info" width="90%" class="m-auto mb-2 mt-2" outlined>
          <span>
            <b> {{ p.objeto.tipo }}: </b>
            {{ p.objeto.dadosOriginais.diplomaFonte }}
            - {{ p.objeto.dadosOriginais.numero }} -
            {{ p.objeto.dadosOriginais.sumario }}
          </span>
        </v-alert>

        <v-divider class="m-auto mb-2" />
      </span>

      <!-- Aviso quando a legislação foi criada a partir de um RADA -->
      <Campo
        v-for="(info, campo) in dados"
        :key="campo"
        :nome="transformaKeys(campo)"
        color="neutralpurple"
      >
        <template v-slot:conteudo>
          <div>
            <div v-if="!(info instanceof Array)">
              {{ info ? info : "O campo encontra-se vazio." }}
            </div>

            <div v-else>
              <v-data-table
                v-if="campo == 'entidadesSel'"
                :headers="entidadesHeaders"
                :items="info"
                :footer-props="footerPropsEntidades"
              >
                <template v-slot:no-data>
                  <v-alert type="error" width="100%" class="m-auto mb-2 mt-2" outlined>
                    Nenhuma entidade selecionada...
                  </v-alert>
                </template>
              </v-data-table>

              <v-data-table
                v-else-if="campo == 'processosSel'"
                :headers="processosHeaders"
                :items="info"
                :footer-props="footerPropsProcessos"
              >
                <template v-slot:no-data>
                  <v-alert type="error" width="100%" class="m-auto mb-2 mt-2" outlined>
                    Nenhum processo selecionado...
                  </v-alert>
                </template>
              </v-data-table>
            </div>
          </div>
        </template>
      </Campo>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapKeys } from "@/utils/utils";
import Campo from "@/components/generic/CampoCLAV";
import { NIVEIS_CONSULTAR_HISTORICO } from "@/utils/consts";

export default {
  props: ["p"],

  components: {
    Campo,
  },
  data() {
    return {
      entidadesHeaders: [
        { text: "Sigla", value: "sigla", class: "subtitle-1" },
        { text: "Designação", value: "designacao", class: "subtitle-1" },
      ],
      footerPropsEntidades: {
        "items-per-page-text": "Entidades por página",
        "items-per-page-options": [5, 10, -1],
        "items-per-page-all-text": "Todas",
      },
      processosHeaders: [
        { text: "Código", value: "codigo", class: "subtitle-1" },
        { text: "Título", value: "titulo", class: "subtitle-1" },
      ],
      footerPropsProcessos: {
        "items-per-page-text": "Processos por página",
        "items-per-page-options": [5, 10, -1],
        "items-per-page-all-text": "Todos",
      },
    };
  },

  computed: {
    dados() {
      return this.p.objeto.dados;
    },
  },

  methods: {
    temPermissaoConsultarHistorico() {
      return NIVEIS_CONSULTAR_HISTORICO.includes(this.$userLevel());
    },

    verHistorico() {
      this.$emit("verHistorico");
    },

    transformaKeys(key) {
      return mapKeys(key);
    },
  },
};
</script>

<style scoped></style>
