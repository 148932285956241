var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.historico.classes.dados,"footer-props":_vm.footer_props,"items-per-page":5,"item-key":"dados.codigo.dados","show-expand":"","single-expand":""},on:{"item-expanded":_vm.clicked},scopedSlots:_vm._u([{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.dados.codigo.dados))]}},{key:"item.titulo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.dados.titulo.dados))]}},(_vm.tipo === 'TS Organizacional')?{key:"item.dono",fn:function(ref){
var item = ref.item;
return [(item.dados.dono.dados)?_c('v-icon',[_vm._v(" done ")]):_vm._e()]}}:{key:"item.dono",fn:function(ref){
var item = ref.item;
return _vm._l((item.dados.entidades.dados),function(e){return _c('div',{key:e.sigla},[(e.dono)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo darken-4","outlined":"","label":""}},[_vm._v(" "+_vm._s(e.sigla)+" ")]):_vm._e()],1)})}},(_vm.tipo === 'TS Organizacional')?{key:"item.participante",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dados.participante.dados)+" ")]}}:{key:"item.participante",fn:function(ref){
var item = ref.item;
return _vm._l((item.dados.entidades.dados),function(e){return _c('div',{key:e.sigla},[(e.participante != 'NP')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo darken-4","outlined":"","label":""}},[_vm._v(" "+_vm._s(e.sigla)+" - "+_vm._s(e.participante)+" ")]):_vm._e()],1)})}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ma-1 elevation-0"},[_c('v-card-text',[_c('v-expansion-panels',[_c('v-expansion-panel',{attrs:{"popout":""}},[_c('v-expansion-panel-header',{staticClass:"clav-linear-background white--text"},[_c('div',[_c('font',{attrs:{"size":"4"}},[_c('b',[_vm._v(" Descritivo da Classe")])])],1)]),_c('v-expansion-panel-content',[_c('VerHistoricoCampo',{attrs:{"campoText":"Notas de aplicação","historicoCampo":item.dados.notasAp,"notas":_vm.expandedProc.notasAp,"tipo":"notasAp","arrayValue":"nota"}}),_c('VerHistoricoCampo',{attrs:{"campoText":"Exemplos de Notas de aplicação","historicoCampo":item.dados.exemplosNotasAp,"notas":_vm.expandedProc.exemplosNotasAp,"tipo":"exemplosNotasAp","arrayValue":"exemplo"}}),_c('VerHistoricoCampo',{attrs:{"campoText":"Notas de Exclusão","historicoCampo":item.dados.notasEx,"notas":_vm.expandedProc.notasEx,"tipo":"notasEx","arrayValue":"nota"}}),_c('VerHistoricoCampo',{attrs:{"campoText":"Termos de Indice","historicoCampo":item.dados.termosInd,"notas":_vm.expandedProc.termosInd,"tipo":"termosInd","arrayValue":"termo"}})],1)],1)],1)],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }