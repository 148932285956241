var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VerHistoricoCampo',{attrs:{"campoText":"Título Tabela Seleção","historicoCampo":_vm.historico.titulo}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider'),_c('h5',[_vm._v("Classes")]),_c('v-data-table',{attrs:{"flat":"","headers":_vm.headers,"items":_vm.historico.classes.dados,"footer-props":_vm.footer_props,"items-per-page":5,"item-key":"dados.codigo.dados","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [(item.dados.tipo.dados == 'Série')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_sr}}):(item.dados.tipo.dados == 'Subsérie')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_ssr}}):(item.dados.tipo.dados == 'N1')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N1}}):(item.dados.tipo.dados == 'N2')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N2}}):(item.dados.tipo.dados == 'N3')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N3}}):_c('i',[_vm._v(_vm._s(item.tipo))])]}},{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.dados.codigo.dados))]}},{key:"item.titulo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.dados.titulo.dados))]}},{key:"item.df",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(!!item.dados.df ? item.dados.df.dados : ''))]}},{key:"item.eFilhoDe",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(!!item.dados.eFilhoDe.dados ? item.dados.eFilhoDe.dados : ''))]}},{key:"item.pca",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(!!item.dados.pca ? item.dados.pca.dados : ''))]}},{key:"item.validade",fn:function(ref){
var item = ref.item;
return [(item.cor == 'verde')?_c('v-avatar',{attrs:{"size":"25","color":"green"}}):(item.cor == 'vermelho')?_c('v-avatar',{attrs:{"size":"25","color":"red"}}):(item.cor == 'amarelo')?_c('v-avatar',{attrs:{"size":"25","color":"amber"}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.dados.tipo.dados != 'Série' && item.dados.tipo.dados != 'Subsérie')?_c('VerHistoricoArea',{attrs:{"historico":item.dados}}):_c('VerHistoricoSerie',{attrs:{"formaContagem":_vm.formaContagem,"historico":item.dados}})],1)]}}])})],1)],1),(!!_vm.historico.UIs.dados[0])?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider'),_c('h5',[_vm._v("Unidades de instalação")]),_c('v-data-table',{attrs:{"headers":_vm.headersUI,"items":_vm.historico.UIs.dados,"footer-props":_vm.footer_props,"items-per-page":5,"item-key":"dados.codigo.dados","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.validade",fn:function(ref){
var item = ref.item;
return [(item.cor == 'verde')?_c('v-avatar',{attrs:{"size":"25","color":"green"}}):(item.cor == 'vermelho')?_c('v-avatar',{attrs:{"size":"25","color":"red"}}):(item.cor == 'amarelo')?_c('v-avatar',{attrs:{"size":"25","color":"amber"}}):_vm._e()]}},{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.dados.codigo.dados))]}},{key:"item.titulo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.dados.titulo.dados))]}},{key:"item.classesAssociadas",fn:function(ref){
var item = ref.item;
return [(!!item.dados.classesAssociadas.dados[0])?_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item.dados.classesAssociadas.dados),function(classe,i){return _c('li',{key:i},[_c('span',{staticStyle:{"padding-right":"10px"}},[(classe.tipo == 'Série')?_c('img',{staticStyle:{"width":"23px","height":"23px"},attrs:{"src":_vm.svg_sr}}):_c('img',{staticStyle:{"width":"23px","height":"23px"},attrs:{"src":_vm.svg_ssr}})]),_c('span',[_vm._v(_vm._s(classe.codigo))])])}),0):_c('p',[_c('br'),_vm._v("Não tem séries/subséries associadas! ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('VerHistoricoUI',{attrs:{"historico":item.dados}})],1)]}}],null,false,3119155114)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }