var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"indigo darken-4 title white--text",attrs:{"dark":""}},[_vm._v(" Comparação de duas etapas ")]),_c('v-card-text',{staticClass:"ma-0"},[_c('v-stepper',{attrs:{"alt-labels":""},model:{value:(_vm.etapa),callback:function ($$v) {_vm.etapa=$$v},expression:"etapa"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.etapa > 1,"step":"1"}},[_vm._v(" Etapas a comparar ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.etapa > 2,"step":"2"}},[_vm._v(" Comparação ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-select',{staticClass:"m-2 mt-4",attrs:{"items":_vm.etapasHistorico,"label":"Escolha duas etapas a comparar","filled":"","clearable":"","multiple":"","chips":"","hide-selected":"","deletable-chips":""},model:{value:(_vm.etapasSelecionadas),callback:function ($$v) {_vm.etapasSelecionadas=$$v},expression:"etapasSelecionadas"}},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"m-auto ",attrs:{"type":"info","width":"99%","outlined":""}},[_vm._v(" Sem mais dados a mostrar. ")])],1)],2),_c('v-btn',{staticClass:"mt-9",attrs:{"disabled":_vm.etapasSelecionadas.length !== 2,"color":"primary"},on:{"click":function($event){return _vm.gerarTabela()}}},[_vm._v(" Continuar ")])],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{staticClass:"ma-1"},[(_vm.defaultHeaders.length !== 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.defaultHeaders,"items":_vm.dadosTabela,"hide-default-footer":"","calculate-widths":"","footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.campo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.campo))])]}},{key:"item.colunaA",fn:function(ref){
var item = ref.item;
return [(
                    item.colunaA === undefined ||
                      item.colunaA.dados === '' ||
                      item.colunaA.dados === null
                  )?_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":"grey","icon":"not_interested"}},[_vm._v(" Campo não preenchido ")]):(item.colunaA.dados instanceof Array)?_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":_vm.cores(item.colunaA.cor),"icon":_vm.icons(item.colunaA.cor)}},[(item.colunaA.dados.length === 0)?_c('v-chip',{attrs:{"color":"grey","label":"","outlined":""}},[_vm._v(" A lista está vazia. ")]):_c('ul',{staticClass:"ma-0"},_vm._l((item.colunaA.dados),function(dado){return _c('span',{key:dado.sigla},[(dado.sigla)?_c('li',[_vm._v(" "+_vm._s(dado.sigla)+" ")]):_vm._e(),(dado.nota)?_c('li',[_vm._v(" "+_vm._s(dado.nota)+" ")]):_c('li',[_vm._v(" "+_vm._s(dado.codigo)+" ")])])}),0)],1):_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":_vm.cores(item.colunaA.cor),"icon":_vm.icons(item.colunaA.cor)}},[_vm._v(" "+_vm._s(item.colunaA.dados)+" ")])]}},{key:"item.colunaB",fn:function(ref){
                  var item = ref.item;
return [(
                    item.colunaB === undefined ||
                      item.colunaB.dados === '' ||
                      item.colunaB.dados === null
                  )?_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":"grey","icon":"not_interested"}},[_vm._v(" Campo não preenchido ")]):(item.colunaB.dados instanceof Array)?_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":_vm.cores(item.colunaB.cor),"icon":_vm.icons(item.colunaB.cor)}},[(item.colunaB.dados.length === 0)?_c('v-chip',{attrs:{"color":"grey","label":"","outlined":""}},[_vm._v(" A lista está vazia. ")]):_c('ul',{staticClass:"ma-0"},_vm._l((item.colunaB.dados),function(dado){return _c('span',{key:dado.sigla},[(dado.sigla)?_c('li',[(
                            _vm.novoItemAdicionado(
                              dado.sigla,
                              item.colunaA,
                              'sigla'
                            )
                          )?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(dado.sigla))]):_c('span',[_vm._v(" "+_vm._s(dado.sigla)+" ")])],1):_vm._e(),(dado.nota)?_c('li',[(
                            _vm.novoItemAdicionado(
                              dado.nota,
                              item.colunaA,
                              'nota'
                            )
                          )?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(dado.nota))]):_c('span',[_vm._v(" "+_vm._s(dado.nota)+" ")])],1):_c('li',[(
                            _vm.novoItemAdicionado(
                              dado.codigo,
                              item.colunaA,
                              'codigo'
                            )
                          )?_c('v-badge',{attrs:{"right":"","dot":"","inline":""}},[_vm._v(_vm._s(dado.codigo))]):_c('span',[_vm._v(" "+_vm._s(dado.codigo)+" ")])],1)])}),0)],1):_c('v-alert',{staticClass:"pa-2 ma-2 ml-0 mr-0",attrs:{"border":"right","text":"","color":_vm.cores(item.colunaB.cor),"icon":_vm.icons(item.colunaB.cor)}},[_vm._v(" "+_vm._s(item.colunaB.dados)+" ")])]}}],null,false,148295991)}):_vm._e()],1),_c('v-btn',{staticClass:"mt-5",attrs:{"disabled":_vm.etapasSelecionadas.length !== 2,"color":"primary"},on:{"click":function($event){return _vm.voltar()}}},[_vm._v(" Voltar ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-4","rounded":"","dark":""},on:{"click":function($event){return _vm.fechar()}}},[_vm._v(" Fechar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }